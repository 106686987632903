/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'gatsby';
import 'twin.macro';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import config from '../utils/config';
import { Subtitle, Heading, Paragraph } from '../components/atoms';

const ProductsPage = () => (
  <Layout>
    <Seo
      title="Products"
      description={config.description}
      url={config.siteUrl}
      image={config.logo}
    />
    <div tw="mx-auto mt-24 container px-4 mb-24">
      <div tw="text-center">
        <Heading isLarge>Our Products</Heading>
        <Paragraph>
          See how we deliver the best solution to our clients with our smart
          products and solutions.
        </Paragraph>
      </div>
      <div tw="grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-6 mt-12 justify-center">
        {config?.productsData.map((item) => (
          <Link to={`/product/${item.slug}`} key={item.id}>
            <div tw="bg-white shadow-3xl border border h-full">
              <img
                src={item.image}
                alt={item.title}
                tw="w-full h-56 object-cover"
              />
              <div tw="px-6 py-5">
                <Subtitle fontNormal>{item.title}</Subtitle>
                <div tw="mt-3">
                  {item.tags &&
                    item.tags.map((tag) => (
                      <div
                        key={tag}
                        tw="bg-green-200 text-green-700 mr-2 text-xs inline-flex items-center px-3 py-1 rounded-full"
                      >
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </Layout>
);
export default ProductsPage;
